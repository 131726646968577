import React from 'react'

import { LogosTicker, Title, SubTitle } from 'ui'

import SectionWithScrollBg from 'components/SectionWithScrollBg'
import ServicesItemsSection from './ServicesItemsSection'
import ToolsSection from './ToolsSection'
import TextWithCubes from 'components/TextWithCubes'
import ClientsSwiper from 'components/ClientsSwiper'
import ButtonsCubeSection from 'components/ButtonsCubeSection'
import Contact from 'components/Contact'

import services_settings from 'site/services/services_settings.json'
import contact_settings from 'site/settings/contact_settings.json'
import siteConfig from 'site/settings/site_config.json'
import services_benefits from 'site/services/services_benefits.json'

const Services = ({ servicesItems }) => {
  return (
    <>
      <LogosTicker />
      <ServicesItemsSection servicesItems={servicesItems} />
      <ToolsSection />
      <SectionWithScrollBg className="servicesWeRock">
        <div className="row">
          <div className="col-xxl-8 col-lg-8 col-md-12">
            <Title text={services_benefits.title_main} />
            <SubTitle text={services_benefits.subtitle_main} />
          </div>
        </div>
        <TextWithCubes
          title_1={services_benefits.title_1}
          sub_title_1={services_benefits.sub_title_1}
          title_2={services_benefits.title_2}
          sub_title_2={services_benefits.sub_title_2}
          title_3={services_benefits.title_3}
          sub_title_3={services_benefits.sub_title_3}
        />
        <ClientsSwiper
          title={services_settings.slider_title}
          subTitle={services_settings.slider_sub_title}
        />
      </SectionWithScrollBg>
      <ButtonsCubeSection
        title={services_settings.client_work_title}
        subTitle={services_settings.client_work_sub_title}
        mapItems={services_settings.client_work_content}
        withCounters
        className="servicesClientWork"
      />
      <Contact
        title={contact_settings.title}
        subTitle={siteConfig.services_contact_description}
      />
    </>
  )
}

export default Services
