import * as styles from './Items.module.scss'

import React from 'react'
import Item from './Item'

const Items = ({ items }) => {
  return (
    <div className={styles.container}>
      {items.map((props, index) => (
        <Item {...props} key={`item-item-${index}`} />
      ))}
    </div>
  )
}

export default Items
