import * as styles from './Content.module.scss'

import React from 'react'

import services_settings from 'site/services/services_settings.json'

import { Title, SubTitle } from 'ui'
import Items from './Items'

const Content = () => {
  return (
    <div className={styles.wrapper}>
      {services_settings.tools_content.map(
        ({ title, description, items }, index) => (
          <div
            className={styles.wrapper__container}
            key={`Content-tools_content-${index}`}>
            <div
              className={styles.wrapper__container__item}
              key={`Content-item-${index}`}>
              <Title text={title} variant="small" className="text-white" />
              <SubTitle
                text={description}
                variant="small"
                className="grey-text"
              />
            </div>
            <div className={styles.wrapper__container__item}>
              <Items items={items} />
            </div>
          </div>
        ),
      )}
    </div>
  )
}

export default Content
