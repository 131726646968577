import React from 'react'
import { isOdd } from 'utils'

import SectionWithScrollBg from 'components/SectionWithScrollBg'
import ServicesItem from './ServicesItem'

const ServicesItemsSection = ({ servicesItems }) => {
  return (
    <SectionWithScrollBg className="servicesItems">
      {servicesItems.map(
        (
          {
            frontmatter: {
              title,
              servicespage_description,
              image: { publicURL },
            },
            fields: { slug },
            id,
          },
          index,
        ) => (
          <ServicesItem
            title={title}
            description={servicespage_description}
            imageUrl={publicURL}
            linkTo={`/services${slug}`}
            isReverse={!!isOdd(index)}
            key={id + index}
          />
        ),
      )}
    </SectionWithScrollBg>
  )
}

export default ServicesItemsSection
