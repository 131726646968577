import React from 'react'

import { graphql } from 'gatsby'

import Layout from 'components/layout'
import Services from 'components/Services'
import Squares from 'components/Services/Squares'
import SEO from 'components/seo'
import services_settings from 'site/services/services_settings.json'

import { isMobile } from 'react-device-detect'

const ServicesPage = ({ location, data }) => {
  return (
    <Layout
      ExtraRightComponent={
        isMobile ? null : (
          <Squares servicesItems={data.allMarkdownRemark.nodes} />
        )
      }
      location={location}
      title={services_settings.title}
      subTitle={services_settings.sub_title}>
      <SEO
        title={'Our Services' || data.site.siteMetadata.title}
        location={location.href}
      />
      <Services servicesItems={data.allMarkdownRemark.nodes} />
    </Layout>
  )
}

export default ServicesPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/(services)/" } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      nodes {
        id
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          servicespage_description
          image {
            publicURL
          }
          homepage_icon {
            relativePath
          }
          processes_title
          processes_description
          processes_list {
            title
            description
            rotation
            icon {
              publicURL
            }
          }
        }
      }
    }
  }
`
