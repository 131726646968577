import * as styles from './Item.module.scss'
import cn from 'classnames'

import React from 'react'
import { ImageFromPath } from 'utils'

import { SubTitle } from 'ui'

const Item = ({ title, icon }) => {
  return (
    <div className={styles.container}>
      <ImageFromPath url={icon} />
      <SubTitle
        text={title}
        variant="small"
        className={cn('text-white', styles.container__text)}
      />
    </div>
  )
}

export default Item
