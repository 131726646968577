import * as styles from './ToolsSection.module.scss'

import React from 'react'

import services_settings from 'site/services/services_settings.json'

import { Title } from 'ui'
import SectionWithScrollBg from 'components/SectionWithScrollBg'
import Content from './Content'

const ToolsSection = () => {
  return (
    <SectionWithScrollBg className="servicesTools">
      <div className="container">
        <Title text={services_settings.tools_title} className="text-white" />
        <Content />
      </div>
    </SectionWithScrollBg>
  )
}

export default ToolsSection
