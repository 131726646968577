import React from 'react'
import cn from 'classnames'

import * as styles from './Squares.module.scss'

import Square from './Square'
import Icon1 from 'components/Icons/squaredIcons/Icon1'

import { isOdd } from 'utils'

import { memo } from 'react'

const getIconIndex = index => {
  if (index === 9) {
    return 4
  } else if (index >= 7) {
    return 3
  } else if (index >= 5) {
    return 2
  } else if (index >= 3) {
    return 1
  } else {
    return 0
  }
}

const getIcon = (arr, index) => {
  const iconIndex = getIconIndex(index + 1)

  try {
    return require(`content/assets/${arr[iconIndex].frontmatter.homepage_icon.relativePath}`)
  } catch (error) {
    console.log('Icon not found')
    console.log(error)
    return Icon1
  }
}

const Squares = memo(({ servicesItems }) => {
  console.log(servicesItems)
  return (
    <div className={cn('col-xxl-4 col-lg-4', styles.container)}>
      {new Array(9).fill(0).map((item, index) => (
        <Square
          Icon={!isOdd(index) ? getIcon(servicesItems, index) : null}
          key={`animated-square-${index}`}
        />
      ))}
    </div>
  )
})

export default Squares
