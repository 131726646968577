import * as styles from './ServicesItem.module.scss'

import React from 'react'
import cn from 'classnames'
import { ImageFromPath } from 'utils'
import { Title, SubTitle, DefaultLink } from 'ui'
import { Link } from 'gatsby'

const ServicesItem = ({ title, description, imageUrl, linkTo, isReverse }) => {
  return (
    <div
      className={cn('container', styles.container, {
        [styles.reverse]: isReverse,
      })}>
      <Link
        to={linkTo}
        className={cn(styles.container__item, 'cardClipHover', {
          [styles.reverse]: isReverse,
        })}>
        <ImageFromPath
          url={imageUrl}
          className={styles.container__item__image}
        />
      </Link>
      <div className={cn(styles.container__item, styles.column)}>
        <Title text={title} />
        <SubTitle text={description} />
        <div className={styles.linkWrap}>
          <DefaultLink to={linkTo} title="Learn more" />
        </div>
      </div>
    </div>
  )
}

export default ServicesItem
