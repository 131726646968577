import React from 'react'

import * as styles from './Square.module.scss'

const Square = ({ Icon }) => {
  return (
    <div className={styles.square}>
      {Icon && <Icon className={styles.square__icon} />}
    </div>
  )
}

export default Square
